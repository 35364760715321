import React, { useEffect } from 'react';
import Home from './Home';
import Privacy from './Privacy';
import Terms from './Terms';
import DownloadPage from './DownloadPage';
import Cookies from './Cookies';
import AcceptableUse from './AcceptableUse';
import Disclaimer from './Disclaimer';
import Business from './Business';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'flowbite';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import TiktokPixel from 'tiktok-pixel';
import ShareApp from './ShareApp';
import Affiliates from './Affiliates';

const TRACKING_ID = 'G-E24KQPS9NB'; //"UA-165871209-1";
ReactGA.initialize(TRACKING_ID);

const FB_PIXEL_ID = '1211509429775635';
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init(FB_PIXEL_ID, null, options);

const TIKTOK_PIXEL_ID = 'CKQQ6ERC77U3M477TOA0';
const tiktokOptions = {
  debug: false, // enable logs
};
TiktokPixel.init(TIKTOK_PIXEL_ID, tiktokOptions);

const App = () => {
  const isShareSubdomain = window.location.hostname.startsWith('share.');

  useEffect(() => {
    ReactGA.pageview(window.location.hostname + window.location.pathname + window.location.search);
    ReactPixel.pageView();
    TiktokPixel.pageView();
  }, []);

  if (isShareSubdomain) {
    return (
      <Router>
        <Routes>
          <Route exact path="/" element={<ShareApp />} />
        </Routes>
      </Router>
    );
  } else {
    return (
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/cookies" element={<Cookies />} />
          <Route path="/acceptable-use" element={<AcceptableUse />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/download" element={<DownloadPage />} />
          <Route path="/business" element={<Business />} />
          <Route path="/affiliates" element={<Affiliates />} />
        </Routes>
      </Router>
    );
  }
};

export default App;
