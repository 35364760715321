import React from 'react';
import FeatureDescription from '../core/FeatureDescription';
import SectionH2 from '../heading/SectionH2';
import RoutinesEnabled from '../../img/routines_enabled.png';
import Buzzer from '../../img/buzzer.png';
import FeedNotif from '../../img/feed_notif.png';
import { useLocation } from 'react-router-dom';

const HowItWorks = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const channel = searchParams.get('channel');
  const getHowItWorksDescriptions = () => {
    if (channel && channel.toLowerCase().includes('instacart')) {
      return [
        {
          title: '1. Tap to unlock your door',
          description: 'Tap to enable automatic entry for Instacart shoppers in the Lowkey app',
          imageSrc: RoutinesEnabled,
          imageAlt: 'Lowkey app screen',
          imageStyle: 'h-80 sm:h-68 lg:h-80 border-2 rounded-3xl py-0.5 border-slate-700 shadow-lg',
        },
        {
          title: '2. Instacart shopper dials your unit',
          description:
            'The Instacart shopper dials your unit from the buzzer, either by dialing your unit code or using the buzzer directory',
          secondaryDescription:
            'If a Passcode is enabled, the Instacart shopper will be prompted to enter the Lowkey passcode after first dialing your unit',
          imageSrc: Buzzer,
          imageAlt: 'Apartment buzzer',
          imageStyle: 'h-80 sm:h-68 lg:h-80 rounded-3xl',
        },
        {
          title: '3. Instacart shopper is buzzed in',
          description:
            "Lowkey buzzes in the Instacart shopper on your behalf and you'll immediately receive a push notification letting you know they've arrived",
          imageSrc: FeedNotif,
          imageAlt: 'Notification screen',
          imageStyle: 'h-80 sm:h-68 lg:h-80 border-2 py-0.5 rounded-3xl border-slate-700 shadow-lg',
        },
      ];
    } else if (channel && channel.toLowerCase().includes('doordash')) {
      return [
        {
          title: '1. Tap to unlock your door',
          description: 'Tap to enable automatic entry for DoorDash drivers in the Lowkey app',
          imageSrc: RoutinesEnabled,
          imageAlt: 'Lowkey app screen',
          imageStyle: 'h-80 sm:h-68 lg:h-80 border-2 rounded-3xl py-0.5 border-slate-700 shadow-lg',
        },
        {
          title: '2. DoorDash driver dials your unit',
          description:
            'The DoorDash driver dials your unit from the buzzer, either by dialing your unit code or using the buzzer directory',
          secondaryDescription:
            'If a Passcode is enabled, the DoorDash driver will be prompted to enter the Lowkey passcode after first dialing your unit',
          imageSrc: Buzzer,
          imageAlt: 'Apartment buzzer',
          imageStyle: 'h-80 sm:h-68 lg:h-80 rounded-3xl',
        },
        {
          title: '3. DoorDash driver is buzzed in',
          description:
            "Lowkey buzzes in the DoorDash driver on your behalf and you'll immediately receive a push notification letting you know they've arrived",
          imageSrc: FeedNotif,
          imageAlt: 'Notification screen',
          imageStyle: 'h-80 sm:h-68 lg:h-80 border-2 py-0.5 rounded-3xl border-slate-700 shadow-lg',
        },
      ];
    } else if (channel && channel.toLowerCase().includes('caregivers')) {
      return [
        {
          title: '1. Tap to unlock your door',
          description: 'Tap to enable secure entry for caregivers in the Lowkey app',
          imageSrc: RoutinesEnabled,
          imageAlt: 'Lowkey app screen',
          imageStyle: 'h-80 sm:h-68 lg:h-80 border-2 rounded-3xl py-0.5 border-slate-700 shadow-lg',
        },
        {
          title: '2. Caregiver dials your unit',
          description:
            'The caregiver dials your unit from the buzzer, either by dialing your unit code or using the buzzer directory',
          secondaryDescription:
            'If a Passcode is enabled, the caregiver will be prompted to enter the Lowkey passcode after first dialing your unit',
          imageSrc: Buzzer,
          imageAlt: 'Apartment buzzer',
          imageStyle: 'h-80 sm:h-68 lg:h-80 rounded-3xl',
        },
        {
          title: '3. Caregiver is buzzed in',
          description:
            "Lowkey buzzes in the caregiver on your behalf and you'll immediately receive a push notification letting you know they've arrived",
          imageSrc: FeedNotif,
          imageAlt: 'Notification screen',
          imageStyle: 'h-80 sm:h-68 lg:h-80 border-2 py-0.5 rounded-3xl border-slate-700 shadow-lg',
        },
      ];
    } else if (channel && channel.toLowerCase().includes('buzz_in_clients')) {
      return [
        {
          title: '1. Set up automatic entry for clients',
          description: 'Tap to enable secure entry for your clients in the Lowkey app',
          imageSrc: RoutinesEnabled,
          imageAlt: 'Lowkey app screen',
          imageStyle: 'h-80 sm:h-68 lg:h-80 border-2 rounded-3xl py-0.5 border-slate-700 shadow-lg',
        },
        {
          title: '2. Client arrives and dials your unit',
          description:
            'Your client dials your unit from the buzzer, using either your unit code or the buzzer directory',
          secondaryDescription:
            'If enabled, the client will be prompted to enter a unique Lowkey passcode for added security',
          imageSrc: Buzzer,
          imageAlt: 'Apartment buzzer',
          imageStyle: 'h-80 sm:h-68 lg:h-80 rounded-3xl',
        },
        {
          title: '3. Client is buzzed in automatically',
          description:
            "Lowkey buzzes in the client on your behalf and you'll immediately receive a push notification letting you know they've arrived",
          imageSrc: FeedNotif,
          imageAlt: 'Notification screen',
          imageStyle: 'h-80 sm:h-68 lg:h-80 border-2 py-0.5 rounded-3xl border-slate-700 shadow-lg',
        },
      ];
    } else if (channel && channel.toLowerCase().includes('delivery')) {
      return [
        {
          title: '1. Set up automatic entry for deliveries',
          description: 'Tap to enable secure entry for delivery drivers in the Lowkey app',
          imageSrc: RoutinesEnabled,
          imageAlt: 'Lowkey app screen',
          imageStyle: 'h-80 sm:h-68 lg:h-80 border-2 rounded-3xl py-0.5 border-slate-700 shadow-lg',
        },
        {
          title: '2. Delivery driver arrives and dials your unit',
          description:
            'The delivery driver dials your unit from the buzzer, using either your unit code or the buzzer directory',
          secondaryDescription:
            'If enabled, the driver will be prompted to enter a unique Lowkey passcode for added security',
          imageSrc: Buzzer,
          imageAlt: 'Apartment buzzer',
          imageStyle: 'h-80 sm:h-68 lg:h-80 rounded-3xl',
        },
        {
          title: '3. Delivery driver is buzzed in automatically',
          description:
            "Lowkey buzzes in the delivery driver on your behalf and you'll immediately receive a push notification letting you know your package has arrived",
          imageSrc: FeedNotif,
          imageAlt: 'Notification screen',
          imageStyle: 'h-80 sm:h-68 lg:h-80 border-2 py-0.5 rounded-3xl border-slate-700 shadow-lg',
        },
      ];
    } else {
      return [
        {
          title: '1. Tap to unlock your door',
          description: 'Tap to enable a Timer, Passcode, or Routine in the Lowkey app',
          imageSrc: RoutinesEnabled,
          imageAlt: 'Lowkey app screen',
          imageStyle: 'h-80 sm:h-68 lg:h-80 border-2 rounded-3xl py-0.5 border-slate-700 shadow-lg',
        },
        {
          title: '2. Guest dials your unit',
          description:
            'Have your guest dial your unit from the buzzer, either by dialing your unit code or using the buzzer directory',
          secondaryDescription:
            'If a Passcode is enabled, your guest will be prompted to enter the Lowkey passcode after first dialing your unit',
          imageSrc: Buzzer,
          imageAlt: 'Apartment buzzer',
          imageStyle: 'h-80 sm:h-68 lg:h-80 rounded-3xl',
        },
        {
          title: '3. Guest is buzzed in',
          description:
            "Lowkey will buzz in the guest on your behalf and you'll immediately receive a push notification letting you know they've arrived",
          imageSrc: FeedNotif,
          imageAlt: 'Notification screen',
          imageStyle: 'h-80 sm:h-68 lg:h-80 border-2 py-0.5 rounded-3xl border-slate-700 shadow-lg',
        },
      ];
    }
  };

  const howItWorksDescriptions = getHowItWorksDescriptions();

  return (
    <div className="px-10">
      <div className="flex flex-col items-center pb-0 pt-1 sm:pb-8">
        <SectionH2 title={'How It Works'} />
        <div className="max-w-4xl space-y-3 text-center font-space-grotesk-regular text-white">
          <p>Lowkey will buzz in guests on your behalf so you don't have to answer buzzer calls.</p>
          <p>Use Lowkey to buzz in guests in 3 easy steps.</p>
        </div>
      </div>

      <div className="sm:flex sm:flex-row sm:justify-center sm:pt-5">
        <div className="flex max-w-7xl flex-col justify-center space-y-16 px-2 pt-16 sm:flex-row sm:space-x-8 sm:space-y-0 sm:px-0 sm:py-0 lg:space-x-12">
          {howItWorksDescriptions.map((description, index) => (
            <FeatureDescription
              key={index}
              customFontSize={'text-2xl lg:text-3xl'}
              title={description.title}
              description={description.description}
              secondaryDescription={description.secondaryDescription}
              imageSrc={description.imageSrc}
              imageAlt={description.imageAlt}
              imageStyle={description.imageStyle}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
