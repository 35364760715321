import React from 'react';
import SectionH2 from '../heading/SectionH2';
import iOSAppStore from '../../img/ios-app-store.svg';
import AndroidAppStore from '../../img/android-app-store.svg';
import Jewels from '../../img/jewels.png';
import Squiggles from '../../img/squiggles.png';
import { useAnalyticsEventTracker } from '../../utils/analytics';
import { useTimer } from '../../contexts/SubscriptionTimerContext';
import { useLocation } from 'react-router-dom';

const SubscriptionCountdownTimer = ({ color }) => {
  const timeLeftToClaimTrial = useTimer();

  const minutes = Math.floor(timeLeftToClaimTrial / 60);
  const seconds = timeLeftToClaimTrial % 60;

  return (
    <span className={`${color} inline-block w-[44px] text-center`}>{`${minutes}:${
      seconds < 10 ? `0${seconds}` : seconds
    }`}</span>
  );
};

const Download = () => {
  const eventTracker = useAnalyticsEventTracker('Download');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const referralCode = searchParams.get('referreral_code') || searchParams.get('referral_code');
  const channel = searchParams.get('channel');

  const isBuzzInClients = channel && channel.toLowerCase() === 'buzz_in_clients';
  const isCaregivers = channel && channel.toLowerCase() === 'caregivers';

  return (
    <div>
      <div class="max-w-screen-4xl mx-auto flex flex-row justify-center lg:px-12">
        <div
          style={{ boxShadow: 'inset 0 0 30px 12px #41E1B0' }}
          class="w-5/6 rounded-3xl border border-green-lowkey py-20 px-8 sm:w-4/5 sm:py-16 sm:px-0"
        >
          <div class="flex flex-row justify-center sm:px-10">
            <div class="hidden w-1/5 sm:visible sm:flex sm:flex-col sm:justify-center">
              <img loading="lazy" src={Jewels} alt={'Jewel decorations on the left'} class="object-contain" />
            </div>
            <div class="text-center sm:flex sm:w-2/3 sm:flex-col sm:justify-center">
              <div className="px-4 pb-1 sm:px-0 sm:pb-0">
                <SectionH2 title={referralCode ? 'Claim Your Free 2 Months!' : 'Claim your 14 day free trial'} />
              </div>
              <div class="space-y-3 pb-10 font-space-grotesk-regular text-white sm:space-y-2 sm:pb-10 2xl:pb-12">
                {referralCode ? (
                  <>
                    <p class="font-space-grotesk-medium font-bold">Get 2 months free!</p>
                    <p class="font-space-grotesk-medium font-bold">
                      Use code {<span className="italic text-green-lowkey">{referralCode}</span>} at checkout in the
                      next {<SubscriptionCountdownTimer color="text-red-500" />} minutes!
                    </p>
                  </>
                ) : (
                  <p class="font-space-grotesk-medium font-bold">
                    Sign up in the next {<SubscriptionCountdownTimer color="text-red-500" />} minutes to claim your 14
                    day free trial
                  </p>
                )}
                {isBuzzInClients ? (
                  <p className="sm:pt-0">Download Lowkey today to streamline client access for your small business.</p>
                ) : isCaregivers ? (
                  <p className="sm:pt-0">Download Lowkey today to simplify caregiver access.</p>
                ) : (
                  <p className="sm:pt-0">Download Lowkey today to upgrade your apartment access experience.</p>
                )}
              </div>
              <div class="flex flex-col justify-center space-y-5 object-scale-down sm:h-12 sm:flex-row sm:justify-center sm:space-x-4 sm:space-y-0">
                <a
                  href="https://apps.apple.com/app/apple-store/id1513284627?pt=121388959&ct=Landing%20Page&mt=8"
                  onClick={() => eventTracker('app_store_view', 'iOS', 'Download')}
                >
                  <img src={iOSAppStore} alt={'iOS App Store Download'} class="mx-auto h-12 sm:h-full" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.lowkeyclient&utm_source=landing-page"
                  onClick={() => eventTracker('app_store_view', 'android', 'Download')}
                >
                  <img src={AndroidAppStore} alt={'Google Play Store Download'} class="mx-auto h-12 sm:h-full" />
                </a>
              </div>
            </div>

            <div class="hidden w-1/5 sm:visible sm:flex sm:flex-col sm:justify-center">
              <img loading="lazy" src={Squiggles} alt={'Jewel decorations on the left'} class="object-contain" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
