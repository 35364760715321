import React from 'react';
import Section from './components/Section';

import Hero from './components/sections/Hero';
import Features from './components/sections/Features';
import SetupProcess from './components/sections/SetupProcess';
import Testimonials from './components/sections/Testimonials';
import FAQ from './components/sections/FAQ';
import Pricing from './components/sections/Pricing';
import Download from './components/sections/Download';
import CompaniesList from './components/sections/CompaniesList';
import ResponsiveHeader from './components/ResponsiveHeader';
import SocialMedia from './components/sections/SocialMedia';
import Footer from './components/sections/Footer';
import { TimerProvider } from './contexts/SubscriptionTimerContext';
import HowItWorks from './components/sections/HowItWorks';
import OurStory from './components/sections/OurStory';

const Home = () => {
  return (
    <div>
      <ResponsiveHeader title="LOWKEY" />
      <TimerProvider>
        <div>
          <main class="pt-16">
            <section id={'hero'}>
              <Hero />
            </section>
            <CompaniesList />
            <Section id={'features'} topPadding="pt-16">
              <Features />
            </Section>
            <Section id={'howItWorks'}>
              <HowItWorks />
            </Section>
            <Section id={'setupProcess'}>
              <SetupProcess />
            </Section>
            <Section id={'testimonials'}>
              <Testimonials />
            </Section>
            <Section id={'story'}>
              <OurStory />
            </Section>
            <Section id={'faq'}>
              <FAQ />
            </Section>
            <Section id={'pricing'}>
              <Pricing />
            </Section>
            <Section id={'download'}>
              <Download />
            </Section>
            <SocialMedia />
            <Footer />
          </main>
        </div>
      </TimerProvider>
    </div>
  );
};

export default Home;
