import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
const ResponsiveHeader = ({ title, useAbsoluteLinkPaths = false, hideNav = false }) => {
  const [isOpen, setOpen] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const saleTerm = searchParams.get('sale');
  const referralCode = searchParams.get('referreral_code') || searchParams.get('referral_code');

  const handleDropDown = () => {
    setOpen(!isOpen);
  };

  const handleTapLink = () => {
    setOpen(false);
  };

  const formattedSaleTerm = saleTerm ? saleTerm.split('_').map((word) => word.toUpperCase()) : '';
  const repeatedSaleContent = new Array(4).fill(
    <>
      <span class="mx-4 uppercase">LIMITED TIME {formattedSaleTerm} SALE!</span>
      <span class="mx-4 uppercase">40% OFF AT CHECKOUT ON ALL YEARLY PLANS</span>
    </>,
  );

  const repeatedReferralContent = new Array(4).fill(
    <span class="mx-4 uppercase">GET 2 MONTHS FREE! USE CODE "{referralCode}" AT CHECKOUT</span>,
  );

  return (
    <>
      {referralCode ? (
        <div class="fixed top-0 z-50 flex bg-yellow-lowkey font-syne-bold">
          <div class="animate-marquee whitespace-nowrap py-2">{repeatedReferralContent}</div>
          <div class="absolute top-0 animate-marquee2 whitespace-nowrap py-2">{repeatedReferralContent}</div>
        </div>
      ) : saleTerm ? (
        <div class="fixed top-0 z-50 flex bg-yellow-lowkey font-syne-bold">
          <div class="animate-marquee whitespace-nowrap py-2">{repeatedSaleContent}</div>
          <div class="absolute top-0 animate-marquee2 whitespace-nowrap py-2">{repeatedSaleContent}</div>
        </div>
      ) : null}
      <nav
        className={`fixed ${
          referralCode || saleTerm ? 'top-10' : 'top-0'
        } left-0 z-20 w-full border-b border-gray-600 bg-black px-2 py-2.5 shadow-sm sm:border-none sm:px-4`}
      >
        <div class="container mx-auto flex flex-wrap items-center justify-between">
          <a href="https://lowkeybuzzer.com/" class="flex items-center">
            <div class="rounded-full bg-green-lowkey">
              <div class="p-2">
                <svg class="h-4 w-4 sm:h-5 sm:w-5" viewBox="0 0 49 49" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill="white"
                    fillRule="evenodd"
                    clip-rule="evenodd"
                    d="M14.9009 17.1318C14.9009 7.7597 22.4985 0.162109 31.8706 0.162109C41.2427 0.162109 48.8403 7.7597 48.8403 17.1318C48.8403 26.5039 41.2427 34.1015 31.8706 34.1015H27.0221V38.95H22.1737V43.7985H17.3252V48.647H0.355469V35.5216L15.2597 20.6173C15.022 19.4796 14.9009 18.3132 14.9009 17.1318ZM22.1738 29.253H31.8708C38.5652 29.253 43.992 23.8262 43.992 17.1318C43.992 10.4375 38.5652 5.01061 31.8708 5.01061C25.1764 5.01061 19.7496 10.4375 19.7496 17.1318C19.7496 18.3406 19.9258 19.523 20.2686 20.6539L20.6899 22.0441L5.20413 37.5299V43.7985H12.4769V38.95H17.3253V34.1015H22.1738V29.253ZM27.0223 17.1318C27.0223 19.8096 29.1931 21.9803 31.8708 21.9803C34.5485 21.9803 36.7193 19.8096 36.7193 17.1318C36.7193 14.4541 34.5485 12.2833 31.8708 12.2833C29.1931 12.2833 27.0223 14.4541 27.0223 17.1318Z"
                  />
                </svg>
              </div>
            </div>
            <span class="self-center whitespace-nowrap pl-3 text-xl font-semibold text-white sm:pl-6 sm:tracking-wide">
              {title}
            </span>
          </a>
          {hideNav ? null : (
            <>
              <button
                onClick={handleDropDown}
                data-collapse-toggle="navbar-sticky"
                type="button"
                class="inline-flex items-center rounded-lg p-2 text-sm text-gray-400 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 md:hidden"
                aria-controls="navbar-sticky"
                aria-expanded="false"
              >
                <span class="sr-only">Open main menu</span>
                <svg
                  class={`h-6 w-6`}
                  aria-hidden={isOpen ? 'false' : 'true'}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
              <div
                class={`w-full items-center justify-between md:order-1 md:flex md:w-auto ${isOpen ? '' : 'hidden'}`}
                id="navbar-sticky"
              >
                <ul class="mt-4 flex flex-col rounded-lg border p-4 font-montserrat-medium md:mt-0 md:flex-row md:space-x-8 md:border-0 md:text-sm md:font-medium ">
                  <li>
                    <a
                      onClick={handleTapLink}
                      href={useAbsoluteLinkPaths ? 'https://www.lowkeybuzzer.com/#features' : '#features'}
                      class="block rounded border-gray-700 py-2 pl-3 pr-4 text-gray-400 hover:bg-gray-700 hover:text-white md:p-0 md:hover:bg-transparent md:hover:text-white"
                      aria-current="page"
                    >
                      Features
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={handleTapLink}
                      href={useAbsoluteLinkPaths ? 'https://www.lowkeybuzzer.com/#howItWorks' : '#howItWorks'}
                      class="block rounded border-gray-700 py-2 pl-3 pr-4 text-gray-400 hover:bg-gray-700 hover:text-white md:p-0 md:hover:bg-transparent md:hover:text-white"
                    >
                      How It Works
                    </a>
                  </li>
                  <li className="sm:hidden lg:flex">
                    <a
                      onClick={handleTapLink}
                      href={useAbsoluteLinkPaths ? 'https://www.lowkeybuzzer.com/#setupProcess' : '#setupProcess'}
                      class="block rounded border-gray-700 py-2 pl-3 pr-4 text-gray-400 hover:bg-gray-700 hover:text-white md:p-0 md:hover:bg-transparent md:hover:text-white"
                    >
                      Setup Process
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={handleTapLink}
                      href={useAbsoluteLinkPaths ? 'https://www.lowkeybuzzer.com/#testimonials' : '#testimonials'}
                      class="block rounded border-gray-700 py-2 pl-3 pr-4 text-gray-400 hover:bg-gray-700 hover:text-white md:p-0 md:hover:bg-transparent md:hover:text-white"
                    >
                      Testimonials
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={handleTapLink}
                      href={useAbsoluteLinkPaths ? 'https://www.lowkeybuzzer.com/#faq' : '#faq'}
                      class="block rounded border-gray-700 py-2 pl-3 pr-4 text-gray-400 hover:bg-gray-700 hover:text-white md:p-0 md:hover:bg-transparent md:hover:text-white"
                    >
                      FAQs
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={handleTapLink}
                      href={useAbsoluteLinkPaths ? 'https://www.lowkeybuzzer.com/#pricing' : '#pricing'}
                      class="block rounded border-gray-700 py-2 pl-3 pr-4 text-gray-400 hover:bg-gray-700 hover:text-white md:p-0 md:hover:bg-transparent md:hover:text-white"
                    >
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={handleTapLink}
                      href={useAbsoluteLinkPaths ? 'https://www.lowkeybuzzer.com/#download' : '#download'}
                      class="block rounded border-gray-700 py-2 pl-3 pr-4 text-gray-400 hover:bg-gray-700 hover:text-white md:p-0 md:hover:bg-transparent md:hover:text-white"
                    >
                      Download
                    </a>
                  </li>
                  <li className="sm:hidden xl:flex">
                    <a
                      onClick={handleTapLink}
                      href={'/affiliates'}
                      class="block rounded border-gray-700 py-2 pl-3 pr-4 text-gray-400 hover:bg-gray-700 hover:text-white md:p-0 md:hover:bg-transparent md:hover:text-white"
                    >
                      Affiliate Program
                    </a>
                  </li>
                  <li className="sm:hidden lg:flex">
                    <a
                      onClick={handleTapLink}
                      href={
                        useAbsoluteLinkPaths ? 'https://www.lowkeybuzzer.com/blog' : 'https://blog.lowkeybuzzer.com'
                      }
                      class="block rounded border-gray-700 py-2 pl-3 pr-4 text-gray-400 hover:bg-gray-700 hover:text-white md:p-0 md:hover:bg-transparent md:hover:text-white"
                    >
                      Blog
                    </a>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
      </nav>
    </>
  );
};

export default ResponsiveHeader;
