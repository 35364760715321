import React from 'react';
import { useLocation } from 'react-router-dom';

const CompaniesList = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const channel = searchParams.get('channel');

  const isCaregiversUseCase = channel && channel.toLowerCase().includes('caregivers');
  const isBuzzInClientsUseCase = channel && channel.toLowerCase().includes('buzz_in_clients');

  return (
    <>
      <div class="flex flex-row justify-center">
        <p class="pb-8 font-syne-bold text-xl text-slate-500 sm:pb-9 sm:text-2xl xl:pb-10">
          {isCaregiversUseCase ? 'Ideal For' : isBuzzInClientsUseCase ? 'Perfect For' : 'Best Used With'}
        </p>
      </div>
      <div class="flex flex-col overflow-x-clip">
        <div class="z-0 bg-green-lowkey shadow-lg" style={{ boxShadow: '0 0 12px 12px #41E1B0' }} />
        <div class="relative flex overflow-x-hidden bg-green-lowkey">
          <div class="flex items-center justify-center py-3 font-montserrat-semi-bold text-lg text-black sm:text-xl lg:text-2xl">
            <div class="animate-marquee whitespace-nowrap">
              {isCaregiversUseCase ? (
                <>
                  <span class="mx-10">Home Health Aides</span>
                  <span class="mx-10">Visiting Nurses</span>
                  <span class="mx-10">Physical Therapists</span>
                  <span class="mx-10">Meal Delivery Services</span>
                  <span class="mx-10">Cleaning Services</span>
                  <span class="mx-10">Family Members</span>
                </>
              ) : isBuzzInClientsUseCase ? (
                <>
                  <span class="mx-10">Therapists</span>
                  <span class="mx-10">Consultants</span>
                  <span class="mx-10">Tutors</span>
                  <span class="mx-10">Personal Trainers</span>
                  <span class="mx-10">Massage Therapists</span>
                  <span class="mx-10">Life Coaches</span>
                  <span class="mx-10">Tattoo Artists</span>
                </>
              ) : (
                <>
                  <span class="mx-10">Postmates</span>
                  <span class="mx-10">Instacart</span>
                  <span class="mx-10">DoorDash</span>
                  <span class="mx-10">Amazon Prime</span>
                  <span class="mx-10">UberEats</span>
                  <span class="mx-10">GrubHub</span>
                </>
              )}
            </div>
            <div class="absolute top-3 animate-marquee2 whitespace-nowrap">
              {isCaregiversUseCase ? (
                <>
                  <span class="mx-10">Home Health Aides</span>
                  <span class="mx-10">Visiting Nurses</span>
                  <span class="mx-10">Physical Therapists</span>
                  <span class="mx-10">Meal Delivery Services</span>
                  <span class="mx-10">Cleaning Services</span>
                  <span class="mx-10">Family Members</span>
                </>
              ) : isBuzzInClientsUseCase ? (
                <>
                  <span class="mx-10">Therapists</span>
                  <span class="mx-10">Consultants</span>
                  <span class="mx-10">Tutors</span>
                  <span class="mx-10">Personal Trainers</span>
                  <span class="mx-10">Massage Therapists</span>
                  <span class="mx-10">Life Coaches</span>
                  <span class="mx-10">Tattoo Artists</span>
                </>
              ) : (
                <>
                  <span class="mx-10">Postmates</span>
                  <span class="mx-10">Instacart</span>
                  <span class="mx-10">DoorDash</span>
                  <span class="mx-10">Amazon Prime</span>
                  <span class="mx-10">UberEats</span>
                  <span class="mx-10">GrubHub</span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompaniesList;
