import React, { useState } from 'react';
import SectionH2 from '../heading/SectionH2';
import Checkmark from '../../img/checkmark.svg';
import ToggleSwitch from '../ui/ToggleSwitch';
import { useAnalyticsEventTracker } from '../../utils/analytics';
import { useTimer } from '../../contexts/SubscriptionTimerContext';
import { useLocation } from 'react-router-dom';

const SubscriptionCountdownTimer = ({ color }) => {
  const timeLeftToClaimTrial = useTimer();

  const minutes = Math.floor(timeLeftToClaimTrial / 60);
  const seconds = timeLeftToClaimTrial % 60;

  return (
    <span className={`${color} inline-block w-[44px] text-center`}>{`${minutes}:${
      seconds < 10 ? `0${seconds}` : seconds
    }`}</span>
  );
};

const Pricing = () => {
  const [monthlyPricing, setMonthlyPricing] = useState(false);
  const eventTracker = useAnalyticsEventTracker('Pricing');
  const disableMonthly = true;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const referralCode = searchParams.get('referreral_code') || searchParams.get('referral_code');
  const channel = searchParams.get('channel');

  const getPricingPlans = () => {
    if (channel && (channel.toLowerCase().includes('instacart') || channel.toLowerCase().includes('delivery'))) {
      return [
        {
          name: 'Lite',
          description: 'Access for occasional deliveries',
          features: ['Unlock your building door', 'Get a local phone number', 'View activity history for 7 days'],
          missingFeatures: [
            'Share access with multiple users',
            'Create secure access codes',
            'Schedule access by time and day',
          ],
          monthlyPrice: (19.47 / 1).toFixed(2),
          annualPrice: (137.97).toFixed(2),
          annualPriceMonthly: (137.97 / 12).toFixed(2),
          color: '#FFFFFF',
        },
        {
          name: 'Standard',
          description: 'Enhanced security for deliveries and share with roommates',
          features: [
            'Unlock your building door',
            'Get a local phone number',
            'Share access with up to 2 users',
            'View activity history for 30 days',
            'Create secure access codes',
          ],
          missingFeatures: ['Schedule access by time and day'],
          monthlyPrice: (19.87 / 1).toFixed(2),
          annualPrice: (142.79).toFixed(2),
          annualPriceMonthly: (142.79 / 12).toFixed(2),
          color: '#FFFF64',
        },
        {
          name: 'Premium',
          description: 'Advanced control for recurring deliveries',
          features: [
            'Unlock your building door',
            'Get a local phone number',
            'Share access with up to 8 users',
            'View unlimited activity history',
            'Create secure access codes',
            'Schedule access by time and day',
          ],
          missingFeatures: [],
          monthlyPrice: (35.89 / 1).toFixed(2),
          annualPrice: (238.47).toFixed(2),
          annualPriceMonthly: (238.47 / 12).toFixed(2),
          color: '#41E1B0',
        },
        {
          name: 'Business',
          description: 'Comprehensive solution for multiple properties',
          features: [
            'Everything included in Premium',
            'Use with multiple residential units',
            'Share subscription with call forwarding to 15 other users',
          ],
          missingFeatures: [],
          monthlyPrice: (69.89 / 1).toFixed(2),
          annualPrice: (502.56).toFixed(2),
          annualPriceMonthly: (502.56 / 12).toFixed(2),
          color: '#242a2d',
        },
      ];
    } else if (channel && channel.toLowerCase().includes('doordash')) {
      return [
        {
          name: 'Lite',
          description: 'Basic access for occasional food deliveries',
          features: ['Unlock your building door', 'Get a local phone number', 'View activity history for 7 days'],
          missingFeatures: [
            'Share access with multiple users',
            'Create secure access codes',
            'Schedule access by time and day',
          ],
          monthlyPrice: (19.47 / 1).toFixed(2),
          annualPrice: (137.97).toFixed(2),
          annualPriceMonthly: (137.97 / 12).toFixed(2),
          color: '#FFFFFF',
        },
        {
          name: 'Standard',
          description: 'Enhanced security for food deliveries and share with roommates',
          features: [
            'Unlock your building door',
            'Get a local phone number',
            'Share access with up to 2 users',
            'View activity history for 30 days',
            'Create secure access codes',
          ],
          missingFeatures: ['Schedule access by time and day'],
          monthlyPrice: (19.87 / 1).toFixed(2),
          annualPrice: (142.79).toFixed(2),
          annualPriceMonthly: (142.79 / 12).toFixed(2),
          color: '#FFFF64',
        },
        {
          name: 'Premium',
          description: 'Advanced control for recurring food deliveries',
          features: [
            'Unlock your building door',
            'Get a local phone number',
            'Share access with up to 8 users',
            'View unlimited activity history',
            'Create secure access codes',
            'Schedule access by time and day',
          ],
          missingFeatures: [],
          monthlyPrice: (35.89 / 1).toFixed(2),
          annualPrice: (238.47).toFixed(2),
          annualPriceMonthly: (238.47 / 12).toFixed(2),
          color: '#41E1B0',
        },
        {
          name: 'Business',
          description: 'Comprehensive solution for multiple properties',
          features: [
            'Everything included in Premium',
            'Use with multiple residential units',
            'Share subscription with call forwarding to 15 other users',
          ],
          missingFeatures: [],
          monthlyPrice: (69.89 / 1).toFixed(2),
          annualPrice: (502.56).toFixed(2),
          annualPriceMonthly: (502.56 / 12).toFixed(2),
          color: '#242a2d',
        },
      ];
    } else if (channel && channel.toLowerCase().includes('caregivers')) {
      return [
        {
          name: 'Lite',
          description: 'Basic access for occasional care visits',
          features: ['Unlock your building door', 'Get a local phone number', 'View activity history for 7 days'],
          missingFeatures: [
            'Share access with multiple users',
            'Create secure access codes',
            'Schedule access by time and day',
          ],
          monthlyPrice: (19.47 / 1).toFixed(2),
          annualPrice: (137.97).toFixed(2),
          annualPriceMonthly: (137.97 / 12).toFixed(2),
          color: '#FFFFFF',
        },
        {
          name: 'Standard',
          description: 'Enhanced security for regular care visits with family sharing',
          features: [
            'Unlock your building door',
            'Get a local phone number',
            'Share access with up to 2 users',
            'View activity history for 30 days',
            'Create secure access codes',
          ],
          missingFeatures: ['Schedule access by time and day'],
          monthlyPrice: (19.87 / 1).toFixed(2),
          annualPrice: (142.79).toFixed(2),
          annualPriceMonthly: (142.79 / 12).toFixed(2),
          color: '#FFFF64',
        },
        {
          name: 'Premium',
          description: 'Advanced control for recurring care visits',
          features: [
            'Unlock your building door',
            'Get a local phone number',
            'Share access with up to 8 users',
            'View unlimited activity history',
            'Create secure access codes',
            'Schedule access by time and day',
          ],
          missingFeatures: [],
          monthlyPrice: (35.89 / 1).toFixed(2),
          annualPrice: (238.47).toFixed(2),
          annualPriceMonthly: (238.47 / 12).toFixed(2),
          color: '#41E1B0',
        },
        {
          name: 'Business',
          description: 'Comprehensive solution for care agencies',
          features: [
            'Everything included in Premium',
            'Use with multiple residential units',
            'Share subscription with call forwarding to 15 other users',
          ],
          missingFeatures: [],
          monthlyPrice: (69.89 / 1).toFixed(2),
          annualPrice: (502.56).toFixed(2),
          annualPriceMonthly: (502.56 / 12).toFixed(2),
          color: '#242a2d',
        },
      ];
    } else if (channel && channel.toLowerCase().includes('buzz_in_clients')) {
      return [
        {
          name: 'Lite',
          description: 'Basic access for occasional client visits',
          features: ['Unlock your building door', 'Get a local phone number', 'View activity history for 7 days'],
          missingFeatures: [
            'Share access with multiple users',
            'Create secure access codes',
            'Schedule access by time and day',
          ],
          monthlyPrice: (19.47 / 1).toFixed(2),
          annualPrice: (137.97).toFixed(2),
          annualPriceMonthly: (137.97 / 12).toFixed(2),
          color: '#FFFFFF',
        },
        {
          name: 'Standard',
          description: 'Enhanced security for regular client visits',
          features: [
            'Unlock your building door',
            'Get a local phone number',
            'Share access with up to 2 users',
            'View activity history for 30 days',
            'Create secure access codes',
          ],
          missingFeatures: ['Schedule access by time and day'],
          monthlyPrice: (19.87 / 1).toFixed(2),
          annualPrice: (142.79).toFixed(2),
          annualPriceMonthly: (142.79 / 12).toFixed(2),
          color: '#FFFF64',
        },
        {
          name: 'Premium',
          description: 'Advanced control for recurring scheduled visits',
          features: [
            'Unlock your building door',
            'Get a local phone number',
            'Share access with up to 8 users',
            'View unlimited activity history',
            'Create secure access codes',
            'Schedule access by time and day',
          ],
          missingFeatures: [],
          monthlyPrice: (35.89 / 1).toFixed(2),
          annualPrice: (238.47).toFixed(2),
          annualPriceMonthly: (238.47 / 12).toFixed(2),
          color: '#41E1B0',
        },
        {
          name: 'Business',
          description: 'Comprehensive solution for multi-building businesses',
          features: [
            'Everything included in Premium',
            'Use with multiple residential units',
            'Share subscription with call forwarding to 15 other users',
          ],
          missingFeatures: [],
          monthlyPrice: (69.89 / 1).toFixed(2),
          annualPrice: (502.56).toFixed(2),
          annualPriceMonthly: (502.56 / 12).toFixed(2),
          color: '#242a2d',
        },
      ];
    } else {
      return [
        {
          name: 'Lite',
          description: 'Just the essentials',
          features: ['Unlock your building door', 'Get a local phone number', 'View activity history for 7 days'],
          missingFeatures: [
            'Share access with multiple users',
            'Create secure access codes',
            'Schedule access by time and day',
          ],
          monthlyPrice: (19.47 / 1).toFixed(2),
          annualPrice: (137.97).toFixed(2),
          annualPriceMonthly: (137.97 / 12).toFixed(2),
          color: '#FFFFFF',
        },
        {
          name: 'Standard',
          description: 'Perfect for roommates and deliveries',
          features: [
            'Unlock your building door',
            'Get a local phone number',
            'Share access with up to 2 users',
            'View activity history for 30 days',
            'Create secure access codes',
          ],
          missingFeatures: ['Schedule access by time and day'],
          monthlyPrice: (19.87 / 1).toFixed(2),
          annualPrice: (142.79).toFixed(2),
          annualPriceMonthly: (142.79 / 12).toFixed(2),
          color: '#FFFF64',
        },
        {
          name: 'Premium',
          description: 'Suited for home automation and large households',
          features: [
            'Unlock your building door',
            'Get a local phone number',
            'Share access with up to 8 users',
            'View unlimited activity history',
            'Create secure access codes',
            'Schedule access by time and day',
          ],
          missingFeatures: [],
          monthlyPrice: (35.89 / 1).toFixed(2),
          annualPrice: (238.47).toFixed(2),
          annualPriceMonthly: (238.47 / 12).toFixed(2),
          color: '#41E1B0',
        },
        {
          name: 'Business',
          description: 'Ideal for businesses & property management',
          features: [
            'Everything included in Premium',
            'Use with multiple residential units',
            'Share subscription with call forwarding to 15 other users',
          ],
          missingFeatures: [],
          monthlyPrice: (69.89 / 1).toFixed(2),
          annualPrice: (502.56).toFixed(2),
          annualPriceMonthly: (502.56 / 12).toFixed(2),
          color: '#242a2d',
        },
      ];
    }
  };

  const pricingPlans = getPricingPlans();

  const pricingComponent = (plan, index) => {
    return (
      <div
        key={index}
        style={{
          boxShadow: `inset 0 0 20px 1px ${plan.color}`,
          borderColor: `${plan.color}`,
          borderWidth: 1,
        }}
        className="flex h-full w-full flex-grow flex-row justify-center rounded-lg sm:w-2/3 md:w-96"
      >
        <div className="flex w-full flex-col space-y-5 py-6 px-9 text-center font-space-grotesk-regular lg:w-96">
          <div className="flex flex-col space-y-1">
            <h3 className="font-syne-bold text-3xl font-bold">{plan.name}</h3>
            <p className="text-lg">{plan.description}</p>
          </div>
          <div className="flex flex-col space-y-3">
            {plan.features.map((feature, index) => (
              <li key={index} className="flex flex-row items-center justify-between text-left text-sm">
                {feature}
                <img src={Checkmark} alt={'white checkmark icon'} class="h-3 w-3 object-contain" />
              </li>
            ))}
            {plan.missingFeatures.map((feature, index) => (
              <li key={index} className="flex flex-row items-center justify-start text-sm line-through opacity-50">
                {feature}
              </li>
            ))}
          </div>
          <div className="flex flex-grow flex-col justify-end">
            <div>
              <span>
                ${monthlyPricing ? plan.monthlyPrice : plan.annualPriceMonthly} / month
                {!monthlyPricing && !disableMonthly && <span className="text-green-lowkey">{' (~40% off)'}</span>}
              </span>
            </div>
            {disableMonthly && <span className="text-gray-400">{`($${plan.annualPrice} billed annually)`}</span>}
            <a
              href="#download"
              onClick={() => eventTracker('pricing_cta_click', plan.name, 'ClickButton')}
              className="mt-3 rounded bg-yellow-lowkey py-2 px-4 font-syne-bold text-black"
            >
              {referralCode ? 'Claim your free 2 months' : 'Claim your 14 day free trial'}
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="px-8 text-white">
      <div className="flex flex-col items-center pb-5 sm:pb-11">
        <SectionH2 title={'Plans & Pricing'} />
        <div className="flex w-full max-w-2xl flex-row flex-wrap items-center justify-around gap-2 sm:flex-row sm:flex-nowrap sm:justify-around sm:gap-0 sm:space-y-0 xl:max-w-3xl">
          <div className="hidden flex-row space-x-3 sm:flex">
            <img src={Checkmark} alt={'white checkmark icon'} class="mx-auto object-contain" />
            <p>Lifetime money back guarantee</p>
          </div>

          <div className="flex flex-grow flex-row justify-center sm:hidden">
            <div className="flex flex-row space-x-3">
              <img src={Checkmark} alt={'white checkmark icon'} class="mx-auto object-contain" />
              <p>Lifetime money back guarantee</p>
            </div>
          </div>
        </div>
        <div class="max-w-4xl space-y-4 pt-4 text-center text-white">
          <p class="font-space-grotesk-regular">
            If you are ever unsatisfied with the service, we will solve your problem or give you a{' '}
            <span class="font-space-grotesk-medium font-bold italic">full lifetime refund.</span>
          </p>
          {referralCode ? (
            <p class="font-space-grotesk-medium font-bold">
              Use code {<span className="italic text-green-lowkey">{referralCode}</span>} at checkout in the next{' '}
              {<SubscriptionCountdownTimer color="text-red-500" />} minutes for 2 months free!
            </p>
          ) : (
            <p class="font-space-grotesk-medium font-bold">
              Sign up in the next {<SubscriptionCountdownTimer color="text-red-500" />} minutes to claim your 14 day
              free trial
            </p>
          )}
        </div>
      </div>

      {disableMonthly ? null : (
        <div className="flex justify-center pb-5 pt-3 sm:pb-1 sm:pt-0">
          <label className="flex items-center">
            <span className={`mr-3 text-lg ${!monthlyPricing ? 'opacity-50' : 'text-bold'}`}>Monthly</span>
            <ToggleSwitch
              initialState={!monthlyPricing}
              onToggle={() => {
                setMonthlyPricing(!monthlyPricing);
                eventTracker('toggle_annual_pricing', 'ToggleAnnualPricing');
              }}
            />
            <div className="relative">
              <span className={`ml-3 text-lg ${monthlyPricing ? 'opacity-50' : 'text-bold'}`}>Yearly</span>
              <span
                className={`absolute text-lg ${
                  monthlyPricing ? 'opacity-50' : 'text-bold'
                } whitespace-nowrap text-green-lowkey`}
                style={{ left: '105%' }}
              >{`(save ~40%)`}</span>
            </div>
          </label>
        </div>
      )}

      <div class="flex flex-grow flex-row justify-center overflow-x-auto sm:-mx-8 sm:px-8 sm:pt-5">
        <div className="flex w-full flex-col items-center space-y-4 sm:space-y-6 lg:flex-row lg:space-y-0 lg:space-x-4 xl:space-x-6 2xl:space-x-8">
          {pricingPlans.map((plan, index) => pricingComponent(plan, index))}
        </div>
      </div>

      <div className="pt-4 text-center text-slate-300">
        <p className="text-lg font-medium">Manage multiple rental properties?</p>
        <p className="pt-1 text-sm">
          Learn more about{' '}
          <a className="text-sm text-green-lowkey underline opacity-80" href="/business">
            Lowkey for Short Term Rentals
          </a>
        </p>
      </div>
    </div>
  );
};

export default Pricing;
